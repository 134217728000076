import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroHeader from "../components/aboutUs/heroHeader"
import FeaturedPartnersLogo from "../components/aboutUs/featuredLogos"
import CtaPhotoCards from "../components/aboutUs/CtaPhotoCards"
import CTA from "../components/aboutUs/CTAsContent"
import AboutFeatureBlocks from "../components/aboutUs/featureBlocks/featureBlocks"
import "./about-us.sass"
import AboutUsSEO from "../components/aboutUs/seo"

import "../global-styless/global.scss"

const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUsSEO />
      <div id="about_us_page">
        <HeroHeader />
        <CTA />
        <AboutFeatureBlocks />
        <FeaturedPartnersLogo />
        <CtaPhotoCards />
      </div>
    </Layout>
  )
}

export default AboutUsPage
