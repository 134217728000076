import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedLogos from "../featuredLogos"

const FeaturedPartnersLogo = () => {
  const {
    sanityAboutUsPage: { aboutUsPartnersLogo: data },
  } = useStaticQuery(graphql`
    query aboutUsPartnersQuery {
      sanityAboutUsPage {
        aboutUsPartnersLogo {
          title
          logos {
            title
            logoImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            linkPath
          }
        }
      }
    }
  `)

  return <FeaturedLogos data={data} />
}

export default FeaturedPartnersLogo
