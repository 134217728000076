import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"

import MyLink from "../../myLink"
import BlocksContent from "../../../components/blocksContent"

import * as style from "./style.module.scss"

const AboutUsFeatureBlock = () => {
  const { sanityAboutUsPage: data } = useStaticQuery(graphql`
    query AboutUsFeatures {
      sanityAboutUsPage {
        altFeatures {
          sectionLabel
          sectionText
          sectionTitle
          sectionPortableText {
            _rawColumnContent
          }
          illustration {
            _key
            _type
            caption
            alt
            attribution
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <div className={style.wrapperInner}>
        {data.altFeatures.map((feature, index) => {
          return (
            <div className={style.featureContainer} key={index}>
              <div className={style.featureText}>
                <h2>{feature.sectionTitle}</h2>
                {feature.sectionPortableText && (
                  <BlocksContent
                    blocks={feature.sectionPortableText._rawColumnContent}
                  />
                )}
              </div>
              {feature.illustration && feature.illustration.asset && (
                <div className={style.featureImgContainer}>
                  <GatsbyImage
                    image={feature.illustration.asset.gatsbyImageData}
                    alt={feature.illustration.alt}
                    className={style.featureImg}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AboutUsFeatureBlock
