import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CTA from "../../components/cta"
import "./CTAsContent.sass"

const CTAsContent = () => {
  const {
    sanityAboutUsPage: { ctaContent: data },
  } = useStaticQuery(graphql`
    query featureCTAs {
      sanityAboutUsPage {
        ctaContent {
          firstColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          secondColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          reverse
        }
      }
    }
  `)
  return (
    <div id="ctas_content">
      <div className="container">
        {data.map((cta, index) => (
          <CTA
            key={index}
            firstColumn={cta.firstColumn._rawColumnContent}
            secondColumn={cta.secondColumn._rawColumnContent}
            mobileReverse={cta.reverse}
          />
        ))}
      </div>
    </div>
  )
}

export default CTAsContent
